<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    actions-visible
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">obras</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_PROJECTS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createProject' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir obra
        </b-button>
      </b-link>
    </template>
    <ProjectsTable />
  </BaseCard>
</template>

<script>
import ProjectsTable from '@/components/projects/table/ProjectsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ProjectsTable,
  },
}
</script>

<style scoped>

</style>
