<template>
  <div>
    <BaseTable
      :resource="$data.$constants.RESOURCES.RESOURCE_PROJECTS"
      :resourceStore="'projects'"
      :columns="fields"
      :filters="filters"
      hide-totals
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearFilters"
      @row-clicked="$router.push({ name: 'viewProject', params: { id: $event.id } })"
    >
      <template #cell(client)="data">
        {{ data.item.client.name }}
      </template>
      <template #cell(province)="data">
        {{ data.item.province.name }}
      </template>
      <template #cell(actions)="data">
        <span>
          <b-link
            :to="{ name: 'viewProject', params: { id: data.item.id} }"
            class="pr-1 d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon"
              size="18"
            />
          </b-link>
          <span
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_PROJECTS,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            class="d-inline-block text-danger"
            @click.stop="deleteProject(data.item.id, data.item.name)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </span>
      </template>
    </BaseTable>
    <ProjectListFilters
      ref="project-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('projects/setFilters', $event)"
    />
  </div>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ProjectListFilters from '@/components/projects/filters/ProjectListFilters.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectsTable',
  components: {
    BaseTable,
    ProjectListFilters,
  },
  data() {
    return {
      filtersVisible: false,
      fields: [
        {
          label: 'Obra', key: 'name', sortable: true, thStyle: { 'min-width': '250px' },
        },
        { label: 'provincia', key: 'province', sortable: true },
        { label: 'jobs activos', key: 'n_jobs', sortable: false },
        {
          label: 'Acciones', key: 'actions', sortable: false, thClass: 'text-center', tdClass: 'text-center', thStyle: { 'min-width': '200px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filters: 'projects/getFilters',
    }),
  },
  methods: {
    handleClearFilters() {
      this.$store.commit('projects/setFilters', {})
      this.$refs['project-list-filters'].clearFilters()
    },
    async deleteProject(projectId, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(projectId, this.$data.$constants.RESOURCES.RESOURCE_PROJECTS)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
